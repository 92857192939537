import './App.css';

function App() {
  return (
    <div className="App" style={{
      backgroundImage: '../public/checkerboard.png',
    }}>
      <div className="container">
        <div className="menu">
          <img
              className={"mom"}
              src={'mom.jpg'}
              alt={'this is mom'}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
